import React, { useEffect, useState } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import StartPaymentButton from '../components/StartPaymentButton';
import Footer from '../components/Footer';
import Plyr from 'plyr-react';
import 'plyr-react/plyr.css';
import './Videos.css';

const paymentApiUrl = process.env.REACT_APP_PAYMENT_API_URL;
const apiUrl = process.env.REACT_APP_API_URL;
const videoBaseUrl = process.env.REACT_APP_VIDEO_BASE_URL;
const videosApiUrl = `${apiUrl}/videos`;

const Videos = () => {
  const { isAuthenticated, user } = useAuth0();
  const [hasAccess, setHasAccess] = useState(false);
  const [videos, setVideos] = useState([]);
  const [loadedVideos, setLoadedVideos] = useState({}); // Tracks which videos are loaded

  useEffect(() => {
    const checkAccess = async () => {
      if (isAuthenticated && user) {
        try {
          // Check user access
          const response = await fetch(`${paymentApiUrl}/check-access`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: user.email }),
          });
          const data = await response.json();
          setHasAccess(data.has_access);

          // Fetch videos if access is granted
          if (data.has_access) {
            const videoResponse = await fetch(videosApiUrl);
            if (!videoResponse.ok) {
              throw new Error('Failed to fetch videos');
            }

            const videoData = await videoResponse.json();
            setVideos(videoData);
          }
        } catch (error) {
          console.error('Error checking access or fetching videos:', error);
          setHasAccess(false);
        }
      }
    };

    checkAccess();
  }, [isAuthenticated, user]);

  // Load video URL when play button is clicked
  const handlePlay = (videoId) => {
    setLoadedVideos((prev) => ({ ...prev, [videoId]: true }));
  };

  if (!isAuthenticated) {
    return (
      <Box>
        <Typography mt={10} mb={3}>
          A videók megtekintéséhez kérlek jelentkezz be!
        </Typography>
        <Footer />
      </Box>
    );
  }

  if (!hasAccess) {
    return (
      <Box>
        <Typography mt={10} mb={3}>
          Az elérés lejárt vagy nem elérhető. Kérlek frissítsd az előfizetésed!
        </Typography>
        <StartPaymentButton text="Megvásárolom (5.990 Ft / év)" />
        <Footer />
      </Box>
    );
  }

  return (
    <div style={{ margin: '0 auto', maxWidth: '1100px', padding: '20px' }}>
      <Box mt={2} mb={6}>
        {videos.map((video, index) => (
          <Box key={video.id} mb={4}>
            <Typography variant="h5" align="center" marginBottom="20px">
              {`${video.number}. ${video.title}`}
            </Typography>
            <Box sx={{ maxWidth: 600, width: '100%', margin: '0 auto' }}>
              {/* Dynamically load video source when play button is clicked */}
              {loadedVideos[video.id] ? (
                <Plyr
                  source={{
                    type: 'video',
                    sources: [{ src: `${videoBaseUrl}${video.URL}`, type: 'video/mp4' }],
                  }}
                  options={{ autoplay: true }}
                />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '300px',
                    backgroundColor: '#000',
                    cursor: 'pointer',
                  }}
                  onClick={() => handlePlay(video.id)}
                >
                  <Typography variant="body1" color="white">
                    Kattints ide a videó betöltéséhez
                  </Typography>
                </Box>
              )}
            </Box>
            {index < videos.length - 1 && (
              <Divider style={{ margin: '40px auto', width: '100%', borderTop: '15px solid #ccc' }} />
            )}
          </Box>
        ))}
        <Footer />
      </Box>
    </div>
  );
};

export default Videos;
