import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Typography, Box, CircularProgress } from "@mui/material";
import Footer from "../components/Footer";
import StartPaymentButton from "../components/StartPaymentButton";

const SikeresRegisztracio = () => {
  const { user, isLoading, getAccessTokenSilently, loginWithRedirect } = useAuth0();

  // Refresh session to ensure the logged-in state is properly reflected
  useEffect(() => {
    const refreshSession = async () => {
      try {
        // Attempt to silently refresh the token
        await getAccessTokenSilently();
        console.log("Session refreshed successfully!");
      } catch (error) {
        console.error("Error refreshing session:", error);
        // Fallback: If refreshing fails, redirect to log in
        await loginWithRedirect();
      }
    };

    refreshSession();
  }, [getAccessTokenSilently, loginWithRedirect]);

  if (isLoading) {
    return <CircularProgress style={{ marginTop: "100px" }} />;
  }

  return (
    <div
      style={{
        margin: "0 auto",
        maxWidth: "1000px",
        paddingBottom: "100px",
      }}
    >
      <Box mt={10}>
        <Typography variant="h4" align="center">
          <strong>Sikeres regisztráció!</strong>
        </Typography>
        <Typography mt={4} align="center">
          <p>
            Küldtünk neked egy e-mailt. Az e-mailben található linkre kattintva kérünk
            erősítsd meg e-mail címedet! Ha szeretnél hozzáférni a feladatokhoz, az alábbi
            gombra kattintva be tudsz fizetni:
            <br />
            <br />
            <StartPaymentButton text="Megvásárolom (5.990 Ft / év)" />
          </p>
          <br />
          <p>
            Ha a tanárodtól kapsz hozzáférést, jelezd neki regisztrációd, a többit mi
            intézzük. Ha a{" "}
            <a href="https://lvlmeup.hu" target="blank">
              lvlmeup.hu
            </a>{" "}
            kémia kurzusainak valamelyikére jelentkeztél, küldj egy e-mailt a sikeres
            regisztrációról az{" "}
            <a href="mailto:info@lvlmeup.hu">info@lvlmeup.hu</a> e-mail címre és adunk
            számodra hozzáférést.
          </p>
          <p>Köszönjük, hogy a kemszam.hu-t választottad!</p>
        </Typography>
      </Box>
      <Footer />
    </div>
  );
};

export default SikeresRegisztracio;
