import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography, Container, Divider } from '@mui/material';
import Footer from '../components/Footer';
import AnyagmennyisegKalk from './AnyagmennyisegKalk';
import OldatKalk from './OldatKalk';
import styles from './KemiaKalkulator.module.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const KemiaKalkulator = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box sx={{ width: '100%' }} mt={3} paddingBottom={10}>
        {/* Tabs Container */}
        <Box className="tabs-container">
          <Tabs
            value={value}
            onChange={handleChange}
            centered
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            TabIndicatorProps={{ style: { backgroundColor: '#1976d2' } }}
            className={styles['tabs-root']}
          >
            <Tab label="Leírás" {...a11yProps(0)} className={styles['tab-root']} />
            <Tab label="Anyagmennyiség" {...a11yProps(1)} className={styles['tab-root']} />
            <Tab label="Oldatok" {...a11yProps(2)} className={styles['tab-root']} />
          </Tabs>
        </Box>


        {[0, 1, 2].map((index) => (
          <CustomTabPanel value={value} index={index} key={index}>
            {index === 0 && (
              <>
                <HeroSection />
                <DescriptionSection />
                <AvailableCalculatorsSection />
              </>
            )}
            {index === 1 && <AnyagmennyisegKalk />}
            {index === 2 && <OldatKalk />}
          </CustomTabPanel>
        ))}
      </Box>
      <Footer />
    </div>
  );
};

const HeroSection = () => {
  return (
    <Container className={styles.section}>
      <Box>
        <img 
          src="/assets/lombik.png" 
          alt="Lombik"
          className={styles['section-img']}
        />
        <h2 className={styles['section-title']}>Mi az a KémiaKalkulátor?</h2>
        <p className={styles['section-description']}>
          Ez egy általunk fejlesztett "egyszerű" webapp: <b>te beírod egy
          feladat által megadott adatokat, az app pedig kiszámít mindent,
          amit ebből ki lehet.</b>
        </p>
        <p className={styles['section-description']}>
          Ajánljuk, ha kémia érettségire/dolgozatra készülsz és számolási
          példákat kell megoldanod. Ha valahol elakadsz, ha nem érted hol
          ronthattad el, csak írd be az adatokat a KémiaKalkulátorba.
        </p>
        <p className={styles['section-description']}>
          A KémiaKalkulátort folyamatosan fejlesztjük. Ha találsz valamilyen hibát, vagy van ötleted, hogy mit kéne még tudnia, írj: <a href="mailto:info@lvlmeup.hu">info@lvlmeup.hu</a>
        </p>
      </Box>
      <Divider className={styles['custom-divider']} />
    </Container>
  );
};

const DescriptionSection = () => {
  return (
    <Container className={styles.section}>
      <Box>
        <h2 className={styles['section-title']}>Hogyan működik?</h2>
        <p className={styles['section-description']}>
          Írd be a feladat által megadott adatokat a megfelelő mezőkbe. Nyomj entert vagy kattints a "számol" gombra. Az összes kiszámítható eredmény megjelenik számodra.
        </p>
        <p className={styles['section-description']}>
          Ha valami nem világos, nézd meg az alábbi videót, ahol egy példafeladat megoldását mutatjuk be.
        </p>
        <div className={styles['section-video-container']} style={{ "--video-width": "400px" }}>
          <iframe
            src="https://www.youtube.com/embed/0cpU4wEKe0k?si=CWaCN6yGJoIqHs0c"
            title="Example video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Box>
      <Divider className={styles['custom-divider']} />
    </Container>
  );
};

const AvailableCalculatorsSection = () => {
  return (
    <Container className={styles.section}>
      <Box>
        <h2 className={styles['section-title']}>Elérhető kalkulátorok</h2>
        <p className={styles['section-description']}>
          Egyelőre két témakörben érhetsz el kalkulátorokat.
        </p>
        <p className={styles['section-description']}>
          Az{' '}
          <a
            href="anyagmennyiseg.html"
            className={styles['section-description-link']}
          >
            anyagmennyiség
          </a>{' '}
          kalkulátor részecskék darabszáma, anyagmennyisége, tömege és moláris tömege közötti összefüggéseket tudja kezelni.
        </p>
        <p className={styles['section-description']}>
          Az{' '}
          <a
            href="oldat.html"
            className={styles['section-description-link']}
          >
            oldatok
          </a>{' '}
          kalkulátor az oldatok összetételének kiszámításában (pl. tömegszázalék, koncentráció) tud segítséget nyújtani.
        </p>
      </Box>
    </Container>
  );
};

export default KemiaKalkulator;
