import React from "react";

const InputValidator = ({ id, className, placeholder, onChange }) => {
  const handleInputValidation = (event) => {
    let value = event.target.value;

    // Remove any non-numeric characters except for commas
    value = value.replace(/[^0-9,]/g, "");

    // Replace periods with commas
    value = value.replace(/\./g, ",");

    // Ensure only one comma is allowed
    const commaCount = value.split(",").length - 1;
    if (commaCount > 1) {
      value = value.replace(/,(?=.*?,)/g, "");
    }

    // Update the value in the input field
    event.target.value = value;

    // Call the onChange handler if provided
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <input
      type="text"
      id={id}
      className={className}
      placeholder={placeholder}
      onInput={handleInputValidation}
    />
  );
};

export default InputValidator;
