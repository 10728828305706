import React, { useEffect } from "react";

const EnterKeyHandler = ({ children, onEnter }) => {
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault(); // Prevent default form submission
        onEnter(); // Trigger the passed callback function
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [onEnter]);

  return <div>{children}</div>;
};

export default EnterKeyHandler;