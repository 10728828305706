import React, { useState } from "react";
import styles from "./KemiaKalkulator.module.css"; // Import the CSS module
import EnterKeyHandler from "../components/EnterKeyHandler";
import InputValidator from "../components/InputValidator";
import Tooltip from "../components/Tooltip";

const kemKalkUrl = process.env.REACT_APP_KEMKALK_API_URL;

const OldatKalk = () => {
  const [results, setResults] = useState(null);

  const sections = [
    {
      title: "Oldott anyag",
      fields: [
        { id: "m_oaInput", label: "m<sub>oa</sub> = g", tooltip: "Az oldott anyag tömege. Grammban add meg.", placeholder: "Tömeg" },
        { id: "M_oaInput", label: "M<sub>oa</sub> = g/mol", tooltip: "Az oldott anyag moláris tömege. Szinte soha nem adják meg, az oldott anyag neve alapján kell megállapítanod. Gramm/mólban add meg.", placeholder: "Moláris tömeg" },
        { id: "n_oaInput", label: "n<sub>oa</sub> = mol", tooltip: "Az oldott anyag anyagmennyisége. Mólban add meg.", placeholder: "Anyagmennyiség" },
        { id: "V_oaInput", label: "V<sub>oa</sub> = cm<sup>3</sup>", tooltip: "Az oldott anyag térfogata. Egyelőre csak akkor van értelme, ha az oldott anyag folyadék. KöbCENTIMÉTERben add meg.", placeholder: "Térfogat" },
        { id: "rho_oaInput", label: "ρ<sub>oa</sub> = g/cm³", tooltip: "Az oldott anyag sűrűsége (tömegének és térfogatának hányadosa) g/cm³-ben. Ritkán kell megadni, leginkább térfogatszázalékos feladatoknál.", placeholder: "Sűrűség" },
      ],
    },
    {
      title: "Oldószer",
      fields: [
        { id: "m_oszInput", label: "m<sub>osz</sub> = g", tooltip: "Az oldószer (sokszor a víz) tömege. Grammban add meg.", placeholder: "Tömeg" },
        { id: "M_oszInput", label: "M<sub>osz</sub> = g/mol", tooltip: "Az oldószer moláris tömege. Ez általában a víz, akkor 18,0 g/mol. Ha nem, akkor az oldószer neve alapján kell megállapítanod. Gramm/mólban add meg.", placeholder: "Moláris tömeg" },
        { id: "n_oszInput", label: "n<sub>osz</sub> = mol", tooltip: "Az oldószer anyag anyagmennyisége. Mólban add meg.", placeholder: "Anyagmennyiség" },
        { id: "V_oszInput", label: "V<sub>osz</sub> = cm<sup>3</sup>", tooltip: "Az oldószer térfogata. Vigyázz, ez nem feltétlenül egyenlő az OLDAT térfogatával. KöbCENTIMÉTERben add meg.", placeholder: "Térfogat" },
        { id: "rho_oszInput", label: "ρ<sub>osz</sub> = g/cm³", tooltip: "Az oldószer sűrűsége g/cm³-ben.", placeholder: "Sűrűség" },
      ],
    },
    {
      title: "Oldat",
      fields: [
        { id: "m_oInput", label: "m<sub>o</sub> = g", tooltip: "Az oldat tömege. Grammban add meg.", placeholder: "Tömeg" },
        { id: "n_oInput", label: "n<sub>o</sub> = mol", tooltip: "Az oldat anyagmennyisége. Mólban add meg.", placeholder: "Anyagmennyiség" },
        { id: "V_oInput", label: "V<sub>o</sub> = cm<sup>3</sup>", tooltip: "Az oldat térfogata. KöbCENTIMÉTERben add meg.", placeholder: "Térfogat" },
        { id: "rhoInput", label: "ρ<sub>o</sub> = g/cm³", tooltip: "Az oldat sűrűsége g/cm³-ben.", placeholder: "Sűrűség" },
      ],
    },
    {
      title: "Oldat összetétele",
      fields: [
        { id: "c_oaInput", label: "c<sub>n, oa</sub> = mol/dm<sup>3</sup>", tooltip: "Az oldat anyagmennyiség-koncentrációja. Mól/dm³-ben add meg.", placeholder: "Koncentráció" },
        { id: "c_m_oaInput", label: "c<sub>m, oa</sub> = g/dm<sup>3</sup>", tooltip: "Az oldat tömegkoncentrációja. Gramm/dm³-ben add meg.", placeholder: "Tömegkoncentráció" },
        { id: "mperm_oaInput", label: "m/m%<sub>oa</sub> = %", tooltip: "Az oldott anyag tömegszázaléka.", placeholder: "Tömegszázalék" },
        { id: "npern_oaInput", label: "n/n%<sub>oa</sub> = %", tooltip: "Az oldott anyag anyagmennyiségszázaléka.", placeholder: "Anyagmennyiségszázalék" },
        { id: "vperv_oaInput", label: "V/V%<sub>oa</sub> = %", tooltip: "Az oldott anyag térfogatszázaléka.", placeholder: "Térfogatszázalék" },
        { id: "vperv_oszInput", label: "V/V%<sub>osz</sub> = %", tooltip: "Az oldószer térfogatszázaléka.", placeholder: "Oldószer térfogatszázalék" },
      ],
    },
  ];

  const formatKey = (key) => {
    const keyMapping = {
      m_oa: "m<sub>oa</sub>",
      M_oa: "M<sub>oa</sub>",
      n_oa: "n<sub>oa</sub>",
      V_oa: "V<sub>oa</sub>",
      rho_oa: "ρ<sub>oa</sub>",
      m_osz: "m<sub>osz</sub>",
      M_osz: "M<sub>osz</sub>",
      n_osz: "n<sub>osz</sub>",
      V_osz: "V<sub>osz</sub>",
      rho_osz: "ρ<sub>osz</sub>",
      m_o: "m<sub>o</sub>",
      n_o: "n<sub>o</sub>",
      V_o: "V<sub>o</sub>",
      rho: "ρ<sub>o</sub>",
      c_oa: "c<sub>n, oa</sub>",
      c_m_oa: "c<sub>m, oa</sub>",
      mperm_oa: "m/m%<sub>oa</sub>",
      npern_oa: "n/n%<sub>oa</sub>",
      vperv_oa: "V/V%<sub>oa</sub>",
      vperv_osz: "V/V%<sub>osz</sub>",
    };
    return keyMapping[key] || key;
  };
  
  const getUnit = (key) => {
    const unitMapping = {
      m_oa: "g",
      M_oa: "g/mol",
      n_oa: "mol",
      V_oa: "cm<sup>3</sup>",
      rho_oa: "g/cm³",
      m_osz: "g",
      M_osz: "g/mol",
      n_osz: "mol",
      V_osz: "cm<sup>3</sup>",
      rho_osz: "g/cm³",
      m_o: "g",
      n_o: "mol",
      V_o: "cm<sup>3</sup>",
      rho: "g/cm³",
      c_oa: "mol/dm<sup>3</sup>",
      c_m_oa: "g/dm<sup>3</sup>",
      mperm_oa: "%",
      npern_oa: "%",
      vperv_oa: "%",
      vperv_osz: "%",
    };
    return unitMapping[key] || "";
  };
  
  const formatValue = (value) => {
    if (typeof value === "number") {
      return value.toString().replace(".", ",");
    }
    return value;
  };  

  const handleSubmit = () => {
    const requestBody = {};
    sections.forEach((section) =>
      section.fields.forEach(({ id }) => {
        const fieldValue = document.getElementById(id)?.value.replace(",", ".");
        if (fieldValue) {
          requestBody[id.replace("Input", "")] = fieldValue;
        }
      })
    );

    fetch(`${kemKalkUrl}/lvlup-kemia/szamolas/oldat`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        setResults(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const clearFields = () => {
    sections.forEach((section) =>
      section.fields.forEach(({ id }) => {
        const field = document.getElementById(id);
        if (field) field.value = "";
      })
    );
    setResults(null);
  };

  return (
    <EnterKeyHandler onEnter={handleSubmit}>
      <div className={styles["calculator-container"]}>
        <div className={styles["form-and-results"]}>
          <div className={styles["form-section"]}>
            <form id="oldatForm">
              {sections.map(({ title, fields }) => (
                <div key={title}>
                  <p className={styles["oldat-title"]}>
                    <b>{title}:</b>
                  </p>
                  {fields.map(({ id, label, tooltip, placeholder }) => (
                    <div className={styles["form-group"]} key={id}>
                      <div className={styles["input-row"]}>
                        <span
                          className={styles["property-label"]}
                          dangerouslySetInnerHTML={{
                            __html: label.split("=")[0] + " =",
                          }}
                        ></span>
                        <InputValidator
                          id={id}
                          className={`${styles["form-control"]} ${styles["input-field"]}`}
                          placeholder={placeholder}
                        />
                        <span
                          className={styles["unit-label"]}
                          dangerouslySetInnerHTML={{
                            __html: label.split("=")[1]?.trim(),
                          }}
                        ></span>
                        <Tooltip text={tooltip} />
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </form>
          </div>
          <div
            id="results"
            className={`${styles["results-section"]} ${results ? styles["results-active"] : ""}`}
          >
            {results ? (
              <>
                <h4>Eredmények:</h4>
                {Object.keys(results.result)
                  .filter((key) => results.result[key] !== null) // Filter out null values
                  .map((key) => (
                    <p
                      key={key}
                      dangerouslySetInnerHTML={{
                        __html: `${formatKey(key)} = ${formatValue(results.result[key])} ${getUnit(key)}`,
                      }}
                    ></p>
                  ))}
                <h4>Számolás menete:</h4>
                <ol>
                  {results.explanations.map((explanation, index) => (
                    <li key={index}>{explanation.replace(/\./g, ",")}</li>
                  ))}
                </ol>
              </>
            ) : (
              <p>Itt fog megjelenni az eredmények és a számolás menete.</p>
            )}
          </div>
        </div>

        <div className={styles["button-container"]}>
          <button type="button" className={styles["btn-primary"]} onClick={handleSubmit}>
            Számol
          </button>
          <button type="button" className={styles["btn-secondary"]} onClick={clearFields}>
            Töröl
          </button>
        </div>

        <div className={styles["custom-divider"]}></div>

        {/* Additional Content */}
        <div className={styles["additional-content"]}>
          <h2 className={styles["section-title"]}>Az oldatok kalkulátor</h2>
          <div className={styles["content-text"]}>
            <p>
              A kalkulátor jelenleg kétkomponensű oldatok összetételével kapcsolatos számításokat tud elvégezni.
              Ez azt jelenti, hogy <b>egy oldott anyag</b> és <b>egy oldószer</b> található az oldatban.
            </p>
            <p>
              Az adatoknál az "oa" mindig oldott anyagot, az "osz" oldószert, az "o" oldatot jelent.
            </p>
            <p>
              Amikor beírjátok a <b>megadott adatokat</b>, célszerű figyelnetek, hogy azt <b>ugyanolyan pontosággal tegyétek, ahogyan a
              feladat megadta</b>, így a kiszámolt "értékes jegyek száma" is pontos lesz. Például, ha a feladat azt írja 1,00 gramm, akkor ti is 1,00-t írjatok, ne 1-et.
            </p>
            <p>
              Fontos továbbá, hogy az oldott anyag és az oldószer <b>moláris tömegét</b> általában nem írják le szövegesen a feladatban,
              de <b>az anyagok nevéből ki tudjátok találni</b>. Írjátok be ezt is, hogy minél több mindent kiszámoljon a kalkulátor.
              Amennyiben nincs megadva az oldószer neve, az oldószer a víz.
            </p>
            <p>
              Az "oldott anyag térfogata" (V<sub>oa</sub>) adat folyékony oldott anyagokra (más szóval elegyek esetén) értelmezhető.
            </p>
          </div>
        </div>
        <div className={styles["custom-divider"]}></div>

        {/* Additional Content */}
        <div className={styles["additional-content"]}>
          <h2 className={styles["section-title"]}>Az oldatok</h2>
          <div className={styles["content-text"]}>
            <p>
              A kémiában oldatnak nevezzük a két- vagy többkomponensű (általában folyékony) homogén keverékeket.
              Az ilyen rendszerekben az egyik anyagot (oldott anyag) oszlatjuk szét egy másikban (oldószer).
              Az oldószer koncentrációja az oldott anyag(ok)énál általában jóval nagyobb.
            </p>
            <p>
              A középiskolában ha oldatról beszélünk, folyadékokra gondolunk. Az oldott anyag a feloldódás előtt lehet gáz-, folyadék-, vagy szilárd halmazállapotú.
              Amennyiben az oldott anyag folyadék és mennyisége közelít az oldószeréhez a keveréket elegynek is nevezhetjük.
            </p>
          </div>
        </div>

        <div className={styles["custom-divider"]}></div>

        {/* Additional Content */}
        <div className={styles["additional-content"]}>
          <h2 className={styles["section-title"]}>Az oldatok jellemzői</h2>
          <div className={styles["content-text"]}>
            <p>
              <b>Sűrűség (ρ):</b> Az oldat tömege elosztva az oldat térfogatával. Mértékegysége g/cm<sup>3</sup>.
              ρ = m<sub>o</sub> / V<sub>o</sub>
            </p>
            <p>
              <b>Anyagmennyiség-koncentráció (c<sub>n</sub> vagy simán c):</b> Egy adott összetevő (oldott anyag) anyagmennyisége elosztva az oldat térfogatával.
              Nevezik még molaritásnak vagy moláris koncentrációnak is, mértékegysége a mol/dm<sup>3</sup>. Sokszor csak egyszerűen a koncentráció szót használjuk.
              c<sub>n, oa</sub> = n<sub>oa</sub> / V<sub>o</sub>
            </p>
            <p>
              <b>Tömegkoncentráció (ρ vagy c<sub>m</sub>):</b> Egy adott összetevő (oldott anyag) tömege elosztva az oldat térfogatával.
              Amennyiben ρ-val jelöljük, vigyázzunk, ne keverjük össze a sűrűséggel. Mértékegysége g/dm<sup>3</sup>. A jobb alsó indexben fel szoktuk tüntetni az oldott anyag képletét.
              c<sub>m, oa</sub> = m<sub>oa</sub> / V<sub>o</sub>
            </p>
            <p>
              <b>Tömegszázalék (m/m% vagy w%):</b> Egy adott összetevő tömege elosztva a teljes oldat tömegével (oldott anyag(ok) + oldószer tömege), majd megszorozva 100-al (ezért SZÁZalék).
              A hányadost, melyet nem szorzunk meg 100-al, tömegtörtnek nevezzük. Mértékegysége nincs, hiszen egy viszonyszám (tömeget osztunk tömeggel).
              m/m%<sub>oa</sub> = m<sub>oa</sub> / m<sub>o</sub> ∙ 100
            </p>
            <p>
              <b>Anyagmennyiség-százalék (n/n%):</b> Egy adott összetevő és a teljes oldat anyagmennyiségének (oldott anyag(ok) + oldószer anyagmennyisége) hányadosa szorozva 100-al.
              Mértékegysége nincs.
              n/n%<sub>oa</sub> = n<sub>oa</sub> / n<sub>o</sub> ∙ 100
            </p>
            <p>
              <b>Térfogatszázalék (v/v%):</b> Folyadékelegyeknél használjuk. Egy adott összetevő és a teljes oldat térfogatának hányadosa szorozva 100-al.
              Vigyázz, a teljes oldat térfogata NEM egyenlő az összetevők térfogatának összegével! Mértékegysége nincs.
              V/V%<sub>A</sub> = V<sub>A</sub> / V<sub>o</sub> ∙ 100
            </p>
          </div>
        </div>
        </div>
        </EnterKeyHandler>
      );
    };

export default OldatKalk;

