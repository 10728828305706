import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Fooldal from './pages/Fooldal';
import Navigator from './pages/Navigator';
import Videos from './pages/Videos';
import Feladatok from './pages/Feladatok';
import Fejezetek from './pages/Fejezetek';
import Statisztikak from './pages/Statisztikak';
import SuccessPage from './pages/SuccessPage';
import FailurePage from './pages/FailurePage';
import KemiaKalkulator from './pages/KemiaKalkulator';
import BillingForm from './components/BillingForm';
import CookieConsent from 'react-cookie-consent';
import SikeresRegisztracio from "./pages/SikeresRegisztracio";

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Fooldal />} />
          <Route path="/navigator" element={<Navigator />} />
          <Route path="/navigator/:exerciseId" element={<Navigator />} />
          <Route path="/feladatok" element={<Feladatok />} />
          <Route path="/fejezetek" element={<Fejezetek />} />
          <Route path="/fejezetek/:chapterBId" element={<Fejezetek />} />
          <Route path="/statisztikak" element={<Statisztikak />} />
          <Route path="/videok" element={<Videos />} />
          <Route path="/payment/success" element={<SuccessPage />} />
          <Route path="/payment/failure" element={<FailurePage />} />
          <Route path="/billing-form" element={<BillingForm />} />
          <Route path="/kemiakalkulator" element={<KemiaKalkulator />} />
          <Route path="/sikeres-regisztracio" element={<SikeresRegisztracio />} />
        </Routes>
        <CookieConsent
          location="bottom"
          buttonText="Elfogadom"
          declineButtonText="Elutasítom"
          enableDeclineButton
          containerClasses="cookie-consent-container"
          onAccept={() => {
            console.log("Cookies accepted!");
          }}
          onDecline={() => {
            console.log("Cookies declined!");
          }}
          style={{ background: "#2B373B" }}
          buttonStyle={{
            color: "#4e503b",
            fontSize: "13px",
            backgroundColor: "#FFD700",
          }}
        >
          Oldalunk sütikben tárol információkat, melyek az oldal technikai működéséhez szükségesek. Kérjük olvasd el az{" "}
            <a href="https://lvlmeup.hu/adat/" target="blank" style={{ color: "#FFD700" }}>
              adatvédelmi tájékoztatónkat
            </a>
            .
        </CookieConsent>
      </div>
    </Router>
  );
}

export default App;
