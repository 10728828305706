import React, { useState } from "react";
import styles from './KemiaKalkulator.module.css'; // Import the CSS module
import EnterKeyHandler from "../components/EnterKeyHandler";
import InputValidator from "../components/InputValidator";
import Tooltip from "../components/Tooltip";

const kemKalkUrl = process.env.REACT_APP_KEMKALK_API_URL;

const AnyagmennyisegKalk = () => {
  const [results, setResults] = useState(null);

  const handleSubmit = () => {
    const nInput = document.getElementById("nInput").value.replace(",", ".");
    const mInput = document.getElementById("mInput").value.replace(",", ".");
    const MInput = document.getElementById("MInput").value.replace(",", ".");
    const NInput = document.getElementById("NInput").value.replace(",", ".");
    const ExponentInput = document.getElementById("ExponentInput").value.replace(",", ".");

    const NValue = NInput !== "" ? parseFloat(NInput) : "";
    const exponent = ExponentInput !== "" ? parseFloat(ExponentInput) : "";
    const scientificNotation = NValue !== "" && exponent !== "" ? `${NValue}e${exponent}` : "";

    const requestBody = {};
    if (scientificNotation) requestBody["N"] = scientificNotation;
    if (nInput) requestBody["n"] = nInput;
    if (mInput) requestBody["m"] = mInput;
    if (MInput) requestBody["M"] = MInput;

    fetch(`${kemKalkUrl}/lvlup-kemia/szamolas/anyagmennyiseg`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        setResults(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const clearFields = () => {
    document.querySelectorAll("input").forEach((input) => {
      input.value = "";
    });
    setResults(null);
  };

  const formatValue = (key, value) => {
    const replaceDotWithComma = (str) => str.toString().replace(/\./g, ",");
    switch (key) {
      case "N":
        return `N = ${replaceDotWithComma(value.toExponential())} db`;
      case "n":
        return `n = ${replaceDotWithComma(value)} mol`;
      case "m":
        return `m = ${replaceDotWithComma(value)} g`;
      case "M":
        return `M = ${replaceDotWithComma(value)} g/mol`;
      default:
        return `${key} = ${value}`;
    }
  };

  return (
    <EnterKeyHandler onEnter={handleSubmit}>
      <div className={styles["calculator-container"]}>
        <div className={styles["form-and-results"]}>
          <div className={styles["form-section"]}>
            <form id="myForm">
              {/* Input for N */}
              <div className={styles["form-group"]}>
                <div className={styles["input-row"]}>
                  <span className={styles["property-label"]}>N =</span>
                  <div className={styles["input-group-row"]}>
                    <InputValidator
                      id="NInput"
                      className={styles["form-control"] + " " + styles["large-input"]}
                      placeholder="Darab"
                    />
                    <span className={styles["middle-dot"]}>·</span>
                    <span>10^</span>
                    <InputValidator
                      id="ExponentInput"
                      className={styles["form-control"] + " " + styles["small-input"]}
                      placeholder="Hatvány"
                    />
                  </div>
                  <span className={styles["unit-label"]}>db</span>
                  <Tooltip text="A részecskék darabszáma." />
                </div>
              </div>

              {/* Input for n */}
              <div className={styles["form-group"]}>
                <div className={styles["input-row"]}>
                  <span className={styles["property-label"]}>n =</span>
                  <InputValidator
                    id="nInput"
                    className={styles["form-control"] + " " + styles["input-field"]}
                    placeholder="Anyagmennyiség"
                  />
                  <span className={styles["unit-label"]}>mol</span>
                  <Tooltip text="Anyagmennyiség mólban." />
                </div>
              </div>

              {/* Input for m */}
              <div className={styles["form-group"]}>
                <div className={styles["input-row"]}>
                  <span className={styles["property-label"]}>m =</span>
                  <InputValidator
                    id="mInput"
                    className={styles["form-control"] + " " + styles["input-field"]}
                    placeholder="Tömeg"
                  />
                  <span className={styles["unit-label"]}>g</span>
                  <Tooltip text="Tömeg grammban." />
                </div>
              </div>

              {/* Input for M */}
              <div className={styles["form-group"]}>
                <div className={styles["input-row"]}>
                  <span className={styles["property-label"]}>M =</span>
                  <InputValidator
                    id="MInput"
                    className={styles["form-control"] + " " + styles["input-field"]}
                    placeholder="Moláris tömeg"
                  />
                  <span className={styles["unit-label"]}>g/mol</span>
                  <Tooltip text="Moláris tömeg g/mol-ban." />
                </div>
              </div>
            </form>
          </div>
          <div
            id="results"
            className={`${styles["results-section"]} ${results ? styles["results-active"] : ""}`}
          >
            {results ? (
              <>
                <h4>Eredmények:</h4>
                {Object.keys(results.result).map((key) => (
                  <p key={key}>{formatValue(key, results.result[key])}</p>
                ))}
                <h4>Számolás menete:</h4>
                <ol>
                  {results.explanations.map((explanation, index) => (
                    <li key={index}>{explanation.replace(/\./g, ",")}</li>
                  ))}
                </ol>
              </>
            ) : (
              <p>Itt fog megjelenni az eredmények és a számolás menete.</p>
            )}
          </div>
        </div>

        <div className={styles["button-container"]}>
          <button type="button" className={styles["btn-primary"]} onClick={handleSubmit}>
            Számol
          </button>
          <button type="button" className={styles["btn-secondary"]} onClick={clearFields}>
            Töröl
          </button>
        </div>

        <div className={styles["custom-divider"]}></div>

        {/* Additional Content */}
        <div className={styles["additional-content"]}>
          <h2 className={styles["section-title"]}>Az anyagmennyiség</h2>
          <div className={styles["content-text"]}>
            <p>
              Az anyagmennyiség (n) az SI alapmennyisége, mértékegysége a mól (rövidítve mol). Definíció szerint 1,000 mol anyagmennyiségű anyag pontosan 6,022 · 10<sup>23</sup> elemi egységet (pl. atomot, molekulát) tartalmaz. Ezt a számot Avogadro-számnak (N<sub>A</sub>) nevezzük.
            </p>
            <p>
              Bármely atom egy móljának tömege (m) megegyezik az adott atom relatív atomtömegének grammokban kifejezett értékével. Az anyagok 1 móljának tömegét moláris tömegnek (M) nevezzük, mértékegysége g/mol.
            </p>
            <p>
              Így tehát az anyagmennyiséget a darabszámból vagy a tömegből tudjuk kiszámítani, ebben tud segíteni a fenti kalkulátor.
            </p>
            <p>
              Az egyenletek: n = N/N<sub>A</sub> = m/M
            </p>
          </div>
        </div>
      </div>
    </EnterKeyHandler>
  );
};

export default AnyagmennyisegKalk;
